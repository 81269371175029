<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <van-empty v-if="nodevice" class="custom-image" :image="emptyimg"
              :description="$t('global.devicenotfound')" />
            <van-empty v-if="deviceoffline" :description="$t('global.deviceoffline')">
              <van-button round type="danger" class="bottom-button" @click="checkdeviceinfo($route.query.sn)">{{
                $t('global.retry') }}</van-button>
            </van-empty>
            <van-empty v-if="maintainstatus" image="network" :description="$t('global.devicemaintenance')" />
            <van-empty v-if="deviceerror" image="error" :description="$t('global.deviceerror')" />
            <van-empty v-if="overtime" image="search" :description="$t('global.deviceoutruntime')" />
            <van-empty v-if="noitem" :description="$t('global.deviceavailable')" />
            <van-empty v-if="apierror" image="error" :description="$t('global.deviceapierror')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Starter page component
 */
export default {
  data() {
    return {
      loading: true,
      itemlist: [],
      nodevice: false,
      deviceoffline: false,
      maintainstatus: false,
      overtime: false,
      deviceerror: false,
      noitem: false,
      apierror: false,
      emptyimg: require("@/assets/custom-empty-image.png")
    };
  },
  mounted() {
    this.checkdeviceinfo(this.$route.query.sn);
  },
  methods: {
    checkdeviceinfo(sn) {
      var that = this;
      that.loading = true;
      if (sn) {
        that.$axios
          .post(that.apiuri, {
            action: "init_info",
            sn: sn,
          })
          .then(function (response) {
            that.loading = false;
            if (response.data.status == 200) {
              let data = response.data;
              let now_time_unix = new Date().getTime();
              now_time_unix = now_time_unix / 1000;
              // 删除小数点
              now_time_unix = parseInt(now_time_unix);

              if (data.device.status == 0 || (data.device.endtime < now_time_unix)) {
                that.deviceerror = true
              } else if (data.device.status == 5) {
                that.maintainstatus = true;
              } else if (data.device.online == 0) {
                that.deviceoffline = true;
              } else if (!data.goods) {
                that.noitem = true;
              } else {
                that.$router.push({
                  name: "itemlist",
                  params: { sn: that.$route.query.sn },
                });
              }
            } else {
              that.nodevice = true;
            }
          })
          .catch(function (error) {
            that.apierror = true;
            console.log(error);
            that.loading = false;
          });
      } else {
        that.loading = false;
        that.nodevice = true;

      }

    },
  },
};
</script>
<style>
.custom-image .van-empty__image {
  width: 150px;
  height: 150px;
}

.bottom-button {
  width: 160px;
  height: 40px;
}
</style>