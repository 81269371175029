<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!apierror">

            <!-- <div class="dialog" v-show="showbar">
              <img :src="micoapp.head_img" width="44px" height="44px" class="imgStyle">
              <div class="downFont">{{ micoapp.niconame }}</div>

              <wx-open-launch-weapp class="btnStyle" id="launch-btn" :username="micoapp.ghid"
                :path="`pages/goods/listq=https%3A%2F%2Fmenu.aicoiot.beer%2F${sn}.memu`">
                <script type="text/wxtag-template">
                  <style>.btn { padding: 12px;opacity:0 }</style>
                    <button class="btn">打开小程序</button>
                  </script>
              </wx-open-launch-weapp>
            </div> -->

            <div class="device_info row">
              <h2 v-if="!device.note">{{ device.name }}</h2>
              <h2 v-else>{{ device.note }}</h2>
              <div class="opentime" v-if="device.runtimebegin != '00:00' || device.runtimeend != '00:00'
              ">
                <van-icon name="clock-o" />{{ $t("itemlist.runtime") }}{{ device.runtimebegin }} -
                {{ device.runtimeend }}
              </div>
              <div class="opentime" v-else>
                <van-icon name="clock-o" /> {{ $t("itemlist.runtimealldays") }}
              </div>
            </div>
            <!-- <div
              class="screen_sub1"
              v-if="device.idkey === '7601' || device.idkey === '7600'"
            > -->
            <!-- {{ $t("MOP") }} -->
            <div class="itemlist">
              <div class="item" v-for="(val, idx) in itemlist" :key="idx" @click="toDetail(val.id)">
                <van-col span="10">
                  <van-image width="95%" fit="cover" height="80" :src="val.coverimg" />
                </van-col>
                <van-col span="14">
                  <div class="item_title">{{ val.title }}</div>
                  <div class="item_desc">{{ val.description }}</div>
                  <div class="item_price">
                    <div class="now_price" v-if="val.sale_price > 0">
                      {{ $t(currency) }}{{ val.sale_price }}
                      <sup>{{ val.price }}</sup>
                      /
                      <span v-if="val.unity == 0">{{ val.stock }} ML</span>
                      <span v-else-if="val.unity == 1">{{ val.stock }} CC</span>
                      <span v-else-if="val.unity == 3">{{ $t("detail.unity.type3") }}</span>
                      <span v-else-if="val.unity == 4">{{ $t("detail.unity.type4") }}</span>
                    </div>
                    <div class="now_price" v-else>
                      {{ $t(currency) }}{{ val.price }} /
                      <span v-if="val.unity == 0">{{ val.stock }} ML</span>
                      <span v-else-if="val.unity == 1">{{ val.stock }} CC</span>
                      <span v-else-if="val.unity == 3">{{ $t("detail.unity.type3") }}</span>
                      <span v-else-if="val.unity == 4">{{ $t("detail.unity.type4") }}</span>
                    </div>

                    <!-- <div class="buy_button">
                            <el-button type="primary" size="mini" icon="el-icon-plus" circle></el-button>
                        </div> -->
                  </div>
                </van-col>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <div v-else>
            <van-empty v-if="apierror" image="error" :description="$t('global.deviceapierror')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Starter page component
 */
export default {
  data() {
    return {
      loading: true,
      itemlist: [],
      device: {},
      apierror: false,
      sn: "",
      micoapp: [],
      showbar: false,
      currency: "CNY"
    };
  },
  mounted() {
    this.sn = this.$route.params.sn;
    this.getitemlist();
    this.getmicoapp();
    // console.log(this.wx)
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    var isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
    if (isWeixin) {
      this.showbar = true;
    }
  },
  methods: {
    getitemlist() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(that.apiuri, {
          action: "init_info",
          sn: that.sn,
        })
        .then(function (response) {
          that.loading = false;
          that.itemlist = response.data.goods;
          if (
            !that.itemlist ||
            (response.data.device.status != 1 &&
              response.data.device.online != 1)
          ) {
            that.$router.push({
              name: "Home",
              query: { sn: that.sn },
            });
          }
          that.device = response.data.device;

          let now_time = new Date();
          let now_time_unix = now_time.getTime();
          now_time_unix = now_time_unix / 1000;
          // 删除小数点
          now_time_unix = parseInt(now_time_unix);
          if (that.device.endtime < now_time_unix || !that.device.endtime || that.device.endtime == 0) {
            that.$router.push({
              name: "Home",
              query: { sn: that.sn },
            });
          }
          that.currency = response.data.currency;
        })
        .catch(function (error) {
          that.apierror = true;
          console.log(error);
          that.loading = false;
        });
    },
    toDetail(id) {
      this.$router.push({
        name: "detail",
        params: { id: id },
      });
    },
    getmicoapp() {
      var that = this;
      that.$axios
        .post(that.apiuri, {
          action: "getmicoapp",
          sn: that.sn,
        })
        .then(function (response) {
          that.micoapp = response.data.data;
          // that.wx.config({
          //   debug: false,
          //   appId: that.micoapp.appid,
          //   timestamp: that.micoapp.timestamp,
          //   nonceStr: that.micoapp.noncestr,
          //   signature: that.micoapp.signature,
          //   jsApiList: ["wx-open-launch-weapp"],
          // });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.card-body {
  padding: 0;
  position: relative;
}

.itemlist {
  padding-right: 10px;
  padding-left: 10px;
}

.itemlist .item {
  height: 80px;
  padding-top: 15px;
  padding-bottom: 20px;
  position: relative;
}

.itemlist .item::before {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to bottom, transparent 50%, #abadb1 50%);
}

.itemlist .item .item_title {
  font-size: 20px;
  font-weight: 1000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.itemlist .item .item_desc {
  font-size: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.itemlist .item .item_price {
  height: 25px;
  position: absolute;
  bottom: 20%;
  left: 43%;
}

.itemlist .item .item_price .now_price {
  font-size: 18px;
  font-weight: 600;
}

.itemlist .item .item_price .now_price sup {
  font-size: 10px;
  font-weight: 300;
  text-decoration: line-through;
}

.itemlist .item .item_price .now_price sup::before {
  content: "\00A5";
  margin-right: 1px;
}

.itemlist .item .item_price .buy_button {
  position: absolute;
  bottom: -40%;
  right: -90%;
}

.device_info {
  height: 5rem;
  padding: 0.5rem;
}

.device_info h2 {
  margin-bottom: 1px;
}

.dialog {
  width: 100%;
  height: 56px;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999;
  margin: 0 auto;
  bottom: 15%;
  padding: 2px;
}

.imgStyle {
  float: left;
  margin-left: 12px;
  /*垂直居中*/
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.downFont {
  background-color: #00000000;
  border: 0px solid #00000000;
  color: #FFFFFF;
  font-size: 15px;
  float: left;
  margin-left: 10px;
  line-height: 56px;
}

.btnStyle {
  float: right;
  border-radius: 22px;
  border: 0px solid #00000000;
  height: 44px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 12px;
  color: #FFFFFF;
  background-color: royalblue;
  font-size: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
